import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, get, onValue } from 'firebase/database';
import NavTaskBar from './NavTaskBar'; // Adjust the import path as necessary
import imagePath from './bancsuplex.jpg'; // Update the image path accordingly

const database = getDatabase();

function UserList() {
    const location = useLocation();
    const { username } = location.state; // Retrieve username passed from the Login component
    const [unit1Users, setUnit1Users] = useState([]);
    const [unit2Users, setUnit2Users] = useState([]);
    const [error, setError] = useState('');
    const [userName, setUserName] = useState(''); // State for userName
    const [currentPlacementOpUnit, setCurrentPlacementOpUnit] = useState(''); // Current user placementopUnit

    // Fetch and set the userName and placementopUnit from Firebase
    useEffect(() => {
        if (username) {
            const usersRef = ref(database, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    if (user) {
                        setUserName(user.name);
                        setCurrentPlacementOpUnit(user.placementopUnit); // Set current user's placementopUnit
                    } else {
                        setUserName('User not found');
                    }
                } else {
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error);
                setUserName('Error loading user');
            });
        }
    }, [username]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userRef = ref(database, 'users/');
                const snapshot = await get(userRef);
    
                if (snapshot.exists()) {
                    const allUsers = snapshot.val();
                    const uniqueUsers = Array.from(
                        new Map(Object.values(allUsers).map(user => [user.username, user])).values()
                    );
    
                    // Filter logic for Unit 1 and Unit 2 tables
                    const unit1 = uniqueUsers.filter(user => 
                        // If logged in as username, match placementopUnit
                        user.placementopUnit === `${username}_Unit 1` || 
                        // If logged in as teamLeader, match teamLeader and unitField
                        (user.teamLeader === username && user.unitField === 'Unit 1')
                    );
    
                    const unit2 = uniqueUsers.filter(user => 
                        // If logged in as username, match placementopUnit
                        user.placementopUnit === `${username}_Unit 2` || 
                        // If logged in as teamLeader, match teamLeader and unitField
                        (user.teamLeader === username && user.unitField === 'Unit 2')
                    );
    
                    // Update state with filtered users
                    setUnit1Users(unit1);
                    setUnit2Users(unit2);
                } else {
                    setError('No users found.');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('An error occurred while fetching users.');
            }
        };
    
        fetchUsers();
    }, [username, currentPlacementOpUnit]);
    

    return (
        <div style={styles.wrapper}>
            <NavTaskBar 
                username={username} 
                userName={userName} // Pass the userName fetched from Firebase to the NavTaskBar
            />
            <div style={styles.container}>
                <h2 style={styles.heading}>Sales Unit Points</h2>
                <h4 style={styles.heading2}>Suplex Ashia (Pvt.) Ltd.</h4>
                {error && <p style={styles.error}>{error}</p>}

                <div style={styles.tablesContainer}>
                    {/* Combined Unit 1 Table */}
                    <Table users={unit1Users} title="Unit 1" />
                    {/* Combined Unit 2 Table */}
                    <Table users={unit2Users} title="Unit 2" />
                </div>
            </div>
            {/* Footer */}
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
            <div style={styles.imageContainer} />
        </div>
    );
}

const Table = ({ users, title }) => {
    const totalPoints = users.length * 0.0; // Modify this if actual points are to be fetched or calculated differently
    
    return (
        <div style={styles.tableWrapper}>
            <h3 style={styles.tableHeading}>{title}</h3>
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.tableHeader}>No</th>
                        <th style={styles.tableHeader}>Customer ID</th>
                        <th style={styles.tableHeader}>Name</th>
                        <th style={styles.tableHeader}>Points</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 128, 128, 0.7)' : '#E0FFFF' }}>
                            <td style={styles.tableData}>{index + 1}</td>
                            <td style={styles.tableData}>{user.username}</td>
                            <td style={styles.tableData}>{user.name}</td>
                            <td style={styles.tableData}>0.0</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr style={{ backgroundColor: '#008080', color: 'white' }}>
                        <td style={styles.tableFooterData} colSpan="3">Total {title}</td>
                        <td style={styles.tableFooterData}>{users.length}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#f0f0f0' }}>
                        <td style={styles.tableFooterData} colSpan="3">Total Points</td>
                        <td style={styles.tableFooterData}>{totalPoints}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};





const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Keeps the wrapper at least the full view height
        height: 'auto', // Allow the content to determine height
        background: '#E0FFFF', // Light teal background color
        width: '1620px', // Fixed width for non-responsiveness
    },
    
    imageContainer: {
        width: '100%', // Full width of the viewport
        height: '300px', // Fixed height for the image
        //position: 'absolute', // Position it absolutely
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Ensure the image covers the container
        zIndex: -1, // Place it behind other content
    },
    container: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '900px',
        position: 'relative',
        zIndex: 1,
    },
    heading: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: 'teal',
    },
    heading2: {
        fontSize: '1.0em',
        textAlign: 'center',
        color: 'teal',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
    tablesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '16px'
    },
    tableWrapper: {
        width: '48%',
        borderRadius: '16px'
    },
    tableHeading: {
        fontSize: '1.2em',
        textAlign: 'center',
        color: 'rgba(224, 255, 255, 0.7)',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        borderRadius: '8px',
        marginTop: '20px',
        
    },
    tableHeader: {
        backgroundColor: '#008080',
        color: 'white',
        padding: '8px',
        borderRadius: '8px',
        border: '1px solid #dddddd',
        textAlign: 'left',
    },
    tableData: {
        padding: '8px',
        borderRadius: '8px',
        textAlign: 'left',
    },
    tableFooterData: {
        padding: '8px',
        borderRadius: '8px',
        textAlign: 'left',
        fontWeight: 'bold',
    },
     footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
};

export default UserList;
